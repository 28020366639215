<template>
  <b-container fluid>
    <b-row class="mx-md-1">
      <b-col lg="3" class="profile-center">
        <b-card
          :title="`Orden de Compra ${orders.correlative}`"
          tag="article"
          style="max-width: 20rem; border-radius: 20px"
          class="mb-2"
        >
          <b-card-text>
             <b-row align-v="center">
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>FECHA:</strong></h6>
                <h5>{{ orders.created_at }}</h5>
              </b-form-group>
               <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>ÁREA:</strong></h6>
                <h5>{{ orders.area.name }}</h5>
              </b-form-group>
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>ESTADO:</strong></h6>
                <h5>{{ orders.status_description }}</h5>
              </b-form-group>
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>SOLICITANTE:</strong></h6>
                <h5>{{ orders.user_applicant.fullname }}</h5>
              </b-form-group>
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>DESCRIPCIÓN:</strong></h6>
                <h5>{{ orders.description }}</h5>
              </b-form-group>
            </b-row>
          </b-card-text>
        </b-card> 
      </b-col>
      <b-col lg="9">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Productos</h4>
          </template>

          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table hover :items="products" :fields="columns">

                  <template v-slot:cell(quantityRequest)="data">
                     <span v-if="!data.item.editable">{{ data.item.quantity }}</span>
                    <b-form-input
                      v-else
                      v-model="data.item.quantity"
                      type="text"
                      placeholder="cantidad"
                    ></b-form-input>
                  </template>
                  <template v-slot:cell(verificado)="data">
                    <div  v-if="!data.item.editable">
                      <b-button
                        v-b-tooltip.hover
                        title="Agregar cantidad"
                        variant=" iq-bg-warning mr-1 mb-1"
                        size="sm"
                        @click="edit(data.item)"
                        ><i class="ri-ball-pen-fill ml-1"></i
                      ></b-button>
                    </div>
                    <div v-else>
                      <b-button
                        v-b-tooltip.hover
                        title="Guardar cambios"
                        variant=" iq-bg-success mr-1 mb-1"
                        size="sm"
                        @click="add(data.item)"
                        ><i class="ri-save-line ml-1"></i
                      ></b-button>
                       <b-button
                        v-b-tooltip.hover
                        title="Cancelar"
                        variant=" iq-bg-danger mr-1 mb-1"
                        size="sm"
                        @click="stop(data.item)"
                        ><i class="ri-close-circle-line ml-1"></i>
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <b-button
                  class="mt-3 ml-1 float-right"
                  variant="primary"
                  @click="onSubmit()"
                  >Finalizar Recepción</b-button
                >
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'Show',

  mounted () {
    this.$store.commit("SET_BREADCRUMB", this.date);
    core.index()
  },
  data () {
    return {
      id: this.$route.params.id,
      orders: '',
      products: '',
      quantity: '',
      quantity_old: null,
      form: {
        products: []
      },
      columns: [
        {
          label: "SKU",
          key: "sku",
          class: "text-center",
        },
        { label: "Producto", 
          key: "name", 
          class: "text-center" 
        },
         {
          label: "Categoría",
          key: "categories",
          class: "text-center",
        },
        {
          label: "Cantidad Recibida",
          key: "quantityRequest",
          class: "text-center",
        },
        {
          label: 'Categoría',
          key: 'categories',
          class: 'text-center'
        },
        {
          label: 'Cantidad Recibida',
          key: 'quantityRequest',
          class: 'text-center'
        },
        {
          label: '',
          key: 'verificado',
          class: 'text-center'
        }
      ],
      date: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          html:
            'Recepción de órdenes de compras',
          to: '/purchase_reception/list'
        },
        {
          text: 'Detalles recepción órdenes de Compra',
          active: true
        }
      ]
    }
  },
  async created () {
    this.$store.commit('run')
    await this.loadData()
    this.$store.commit('stop')
  },
  methods: {
    add (item) {
      item.editable = false
    },
    edit(item) {
      this.quantity_old = item.quantity
      item.editable = true;
    },
      stop(item) {
      if (item.quantity !== this.quantity_old) {
        item.quantity = this.quantity_old
      }
      item.editable = false;
    },
    async onSubmit () {
      try {
        this.$store.commit('run')
        const payload = { purchase_order: this.id, products: this.products }
        await this.$store.dispatch('reception/storeReception', payload)
        this.$store.commit('stop')
        core.showSnackbar('success', 'Cambios Realizados Exitosamente')
        this.$router.replace('/purchase_reception/list')
      } catch (error) {
        console.log(error)
        this.$store.commit('stop')
        const errors = error.response.data.errors
        for (const key in errors) {
          core.showSnackbar('error', errors[key][0])
          return false
        }
      }
    },
    async loadData () {
      const orders = await this.$store.dispatch(
        'orders/showPurchaseOrders',
        this.id
      )
      this.orders = orders
      this.products = orders.products.map(item => ({
        id: item.id,
        sku: item.sku,
        name: item.name,
        quantity: item.quantity,
        categories: item.categories[0].name,
        editable: false
      }))
    }
  }
}
</script>
<style>
.custom-text{
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
</style>
